import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import Img from 'gatsby-image'
import ctaVideo from '../videos/cta.mp4'
import Vendor from '../components/vendor/vendor'

class CastAndCrew extends React.Component {
  render () {
    return (
      <Layout>
        <SEO
          title="Cast &amp; Crew"
        />
        <article>
          <div className="c-masthead c-masthead--center">
            <div className="container">
              <div className="row">
                <div className="c-masthead__title-container">
                  <h1 className="c-masthead__title">Cast &amp; Crew</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="c-cast-listing c-cast-listing--nomargin-top">
            <div className="container">
              <div className="row">
                <div className="c-cast-listing__item">
                  <div className="c-cast-listing__item-media-container">
                    <Img
                      fluid={
                        this.props.data.alistairCook.childImageSharp.fluid
                      }
                      alt="Sir Alastair Cook"
                    />
                    <h2 className="c-cast-listing__item-title">
                      Sir Alastair Cook
                    </h2>
                  </div>
                  <div className="c-cast-listing__item-content-container">
                    <p>
                      2006 - 2018<br/>
                      Opening batsman<br/>
                      161 Test Matches, 92 One-Day Internationals and 4 Twenty 20 Internationals.
                    </p>
                    <p>
                      As a child, Cooky lived in Wickhams Bishops in Essex. He boarded at Bedford School and became an unbeaten record-breaker in the school cricket team.
                      Cook went on to play for Essex where he still plays now after retiring from international cricket.
                    </p>
                  </div>
                </div>
                <div className="c-cast-listing__item">
                  <div className="c-cast-listing__item-media-container">
                    <Img
                      fluid={
                        this.props.data.andrewFlower.childImageSharp.fluid
                      }
                      alt="Andrew Flower"
                    />
                    <h2 className="c-cast-listing__item-title">
                      Andrew Flower
                    </h2>
                  </div>
                  <div className="c-cast-listing__item-content-container">
                    <p>
                      2009 - 2014<br/>
                      Coach
                    </p>
                    <p>
                      Andy Flower was born in South Africa. He played for Zimbabwe for over 10 years and was by far the greatest player the country has ever had.
                      He joined the England team as Assistant Coach in 2007 and went on to coach the team to become the number one ranked Test team.
                    </p>
                  </div>
                </div>
                <div className="c-cast-listing__item">
                  <div className="c-cast-listing__item-media-container">
                    <Img
                      fluid={
                        this.props.data.andrewStrauss.childImageSharp.fluid
                      }
                      alt="Andrew Strauss"
                    />
                    <h2 className="c-cast-listing__item-title">
                      Andrew Strauss
                    </h2>
                  </div>
                  <div className="c-cast-listing__item-content-container">
                    <p>
                      2003 - 2012<br/>
                      Captain and opening batsman<br/>
                      100 Test Matches, 127 One-Day Internationals and 4 Twenty 20 Internationals.
                    </p>
                    <p>
                      Strauss was born in Johannesburg, South Africa. He moved to the UK at the age of 6 where he went to Radley College, a public boarding school in Oxfordshire.
                      He then went on to study Economics at Durham University.
                    </p>
                  </div>
                </div>
                <div className="c-cast-listing__item">
                  <div className="c-cast-listing__item-media-container">
                    <Img
                      fluid={
                        this.props.data.davidSaker.childImageSharp.fluid
                      }
                      alt="David Saker"
                    />
                    <h2 className="c-cast-listing__item-title">
                      David Saker
                    </h2>
                  </div>
                  <div className="c-cast-listing__item-content-container">
                    <p>
                      2010 - 2015<br/>
                      Bowling coach
                    </p>
                    <p>
                      David is a former Australian cricket-player turned coach who became the fast bowling coach of the England team for the 2010-11 Ashes.
                      He was also the Australian fast-bowling coach until recently resigning this year.
                    </p>
                  </div>
                </div>
                <div className="c-cast-listing__item">
                  <div className="c-cast-listing__item-media-container">
                    <Img
                      fluid={
                        this.props.data.graemeSwann.childImageSharp.fluid
                      }
                      alt="Graeme Swann"
                    />
                    <h2 className="c-cast-listing__item-title">
                      Graeme Swann
                    </h2>
                  </div>
                  <div className="c-cast-listing__item-content-container">
                    <p>
                      2000 - 2013<br/>
                      Bowler<br/>
                      60 Test Matches, 79 One-Day Internationals and 39 Twenty 20 Internationals.
                    </p>
                    <p>
                      Swann grew up in Northampton. He went to Sponne School in Towcester and played for Northamptonshire for 6 years, and Nottinghamshire for 8 years.
                      Swanny was England’s spinner for 60 Test matches.
                    </p>
                  </div>
                </div>
                <div className="c-cast-listing__item">
                  <div className="c-cast-listing__item-media-container">
                    <Img
                      fluid={
                        this.props.data.ianBell.childImageSharp.fluid
                      }
                      alt="Ian Bell"
                    />
                    <h2 className="c-cast-listing__item-title">
                      Ian Bell
                    </h2>
                  </div>
                  <div className="c-cast-listing__item-content-container">
                    <p>
                      2004 - 2015<br/>
                      Top-order batsman<br/>
                      118 Test Matches, 161 One-Day Internationals and 8 Twenty 20 Internationals.
                    </p>
                    <p>
                      Ian started his cricket career as a junior at his local cricket club in Dunchurch, near Rugby.
                      He still plays for his county team Warwickshire having started there in 1999. He played 118 Test matches for England.
                    </p>
                  </div>
                </div>
                <div className="c-cast-listing__item">
                  <div className="c-cast-listing__item-media-container">
                    <Img
                      fluid={
                        this.props.data.jamesAnderson.childImageSharp.fluid
                      }
                      alt="James Anderson"
                    />
                    <h2 className="c-cast-listing__item-title">
                      James Anderson
                    </h2>
                  </div>
                  <div className="c-cast-listing__item-content-container">
                    <p>
                      2002 - present<br/>
                      Bowler<br/>
                      148 Test Matches, 194 One-Day Internationals and 19 Twenty 20 Internationals.
                    </p>
                    <p>
                      Jimmy grew up in Burnley and played for Burnley Cricket Club from a young age.
                      In 2002, he joined both the Lancashire and England teams where he still plays today as the all-time leading wicket-taker amongst fast bowlers.
                    </p>
                  </div>
                </div>
                <div className="c-cast-listing__item">
                  <div className="c-cast-listing__item-media-container">
                    <Img
                      fluid={
                        this.props.data.jonathanTrott.childImageSharp.fluid
                      }
                      alt="Jonathan Trott"
                    />
                    <h2 className="c-cast-listing__item-title">
                      Jonathan Trott
                    </h2>
                  </div>
                  <div className="c-cast-listing__item-content-container">
                    <p>
                      2007 - 2015<br/>
                      Top-order batsman<br/>
                      52 Test Matches, 68 One-Day Internationals and 7 Twenty 20 Internationals.
                    </p>
                    <p>
                      Trotters was born in Cape Town, playing for South Africa at both under-15 and under-19 level.
                      He moved to England at age 21 and played for Warwickshire for 15 years, retiring in 2018. Trott was part of the England squad for 8 years.
                    </p>
                  </div>
                </div>
                <div className="c-cast-listing__item">
                  <div className="c-cast-listing__item-media-container">
                    <Img
                      fluid={
                        this.props.data.kevinPietersen.childImageSharp.fluid
                      }
                      alt="Kevin Pietersen"
                    />
                    <h2 className="c-cast-listing__item-title">
                      Kevin Pietersen
                    </h2>
                  </div>
                  <div className="c-cast-listing__item-content-container">
                    <p>
                      2004 - 2014<br/>
                      Top-order batsman<br/>
                      104 Test Matches, 136 One-Day Internationals and 37 Twenty 20 Internationals.
                    </p>
                    <p>
                      KP grew up in Pietermaritzburg, South Africa. He moved to England when he was 20 years old and started playing for Nottinghamshire,
                      after which he played for both Hampshire and Surrey.
                    </p>
                  </div>
                </div>
                <div className="c-cast-listing__item">
                  <div className="c-cast-listing__item-media-container">
                    <Img
                      fluid={
                        this.props.data.mattPrior.childImageSharp.fluid
                      }
                      alt="Matt Prior"
                    />
                    <h2 className="c-cast-listing__item-title">
                      Matt Prior
                    </h2>
                  </div>
                  <div className="c-cast-listing__item-content-container">
                    <p>
                      2007 - 2014<br/>
                      Wicketkeeper batsman<br/>
                      79 Test Matches, 68 One-Day Internationals and 10 Twenty 20 Internationals.
                    </p>
                    <p>
                      Matt Prior was born in Johannesburg, South Africa and moved to England when he was 11.
                      He attended Brighton College and joined Sussex County Cricket Club where he played for 14 years.
                    </p>
                  </div>
                </div>
                <div className="c-cast-listing__item">
                  <div className="c-cast-listing__item-media-container">
                    <Img
                      fluid={
                        this.props.data.montyPanesar.childImageSharp.fluid
                      }
                      alt="Monty Panesar"
                    />
                    <h2 className="c-cast-listing__item-title">
                      Monty Panesar
                    </h2>
                  </div>
                  <div className="c-cast-listing__item-content-container">
                    <p>
                      2006 - 2013<br/>
                      Spin-bowler<br/>
                      50 Test Matches, 26 One-Day Internationals and 1 Twenty 20 Internationals.
                    </p>
                    <p>
                      As a child, Monty lived in Luton, Bedfordshire and went to Stopsley High School. He has a degree in Computer Science from Loughborough University.
                      Monty has played for Northamptonshire, Sussex and Essex alongside his international cricket career.
                    </p>
                  </div>
                </div>
                <div className="c-cast-listing__item">
                  <div className="c-cast-listing__item-media-container">
                    <Img
                      fluid={
                        this.props.data.paulCollingwood.childImageSharp.fluid
                      }
                      alt="Paul Collingwood"
                    />
                    <h2 className="c-cast-listing__item-title">
                      Paul Collingwood
                    </h2>
                  </div>
                  <div className="c-cast-listing__item-content-container">
                    <p>
                      2001 - 2017<br/>
                      All-rounder<br/>
                      68 Test Matches, 197 One-Day Internationals and 36 Twenty 20 Internationals.
                    </p>
                    <p>
                      Paul was born in Shotley Bridge, County Durham. He attended Blackfyne Comprehensive School, now known as Consett Academy, where he first discovered cricket.
                      Recently retired, Colly played for Durham for 23 years and the England team for 17 years.
                    </p>
                  </div>
                </div>
                <div className="c-cast-listing__item">
                  <div className="c-cast-listing__item-media-container">
                    <Img
                      fluid={
                        this.props.data.stevenFinn.childImageSharp.fluid
                      }
                      alt="Steven Finn"
                    />
                    <h2 className="c-cast-listing__item-title">
                      Steven Finn
                    </h2>
                  </div>
                  <div className="c-cast-listing__item-content-container">
                    <p>
                      2010 - 2017<br/>
                      Bowler<br/>
                      36 Test Matches, 69 One-Day Internationals and 21 Twenty 20 Internationals.
                    </p>
                    <p>
                      Finny grew up in Watford, Hertfordshire and attended Parmiter’s School. He currently plays for Middlesex where he has played for 14 years.
                    </p>
                  </div>
                </div>
                <div className="c-cast-listing__item">
                  <div className="c-cast-listing__item-media-container">
                    <Img
                      fluid={
                        this.props.data.stuartBroad.childImageSharp.fluid
                      }
                      alt="Stuart Broad"
                    />
                    <h2 className="c-cast-listing__item-title">
                      Stuart Broad
                    </h2>
                  </div>
                  <div className="c-cast-listing__item-content-container">
                    <p>
                      2006 - present<br/>
                      Bowler<br/>
                      126 Test Matches, 121 One-Day Internationals and 56 Twenty 20 Internationals.
                    </p>
                    <p>
                      Broady started his cricket career at a young age, representing Leicestershire in their Under-9 team.
                      From ages 9 to 19 he played for Egerton Park whilst at Oakham School in Rutland. Today, he is playing for England alongside the county team Nottinghamshire.
                    </p>
                  </div>
                </div>
                <div className="c-cast-listing__item">
                  <div className="c-cast-listing__item-media-container">
                    <Img
                      fluid={
                        this.props.data.timBresnan.childImageSharp.fluid
                      }
                      alt="Tim Bresnan"
                    />
                    <h2 className="c-cast-listing__item-title">
                      Tim Bresnan
                    </h2>
                  </div>
                  <div className="c-cast-listing__item-content-container">
                    <p>
                      2006 - present<br/>
                      All-rounder<br/>
                      23 Test Matches, 85 One-Day Internationals and 34 Twenty 20 Internationals.
                    </p>
                    <p>
                      Tim grew up in Pontefract, West Yorkshire and started his cricket career at Townville Cricket Club.
                      In 2003 he joined Yorkshire’s county team who he still plays for now. Brez has played a total of 23 Test matches for England.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="c-cast-listing c-cast-listing--crew c-cast-listing--nomargin-bottom">
            <div className="container">
              <div className="row">
                <div className="c-cast-listing__item">
                  <div className="c-cast-listing__item-media-container">
                    <Img
                      fluid={
                        this.props.data.barneyDouglas.childImageSharp.fluid
                      }
                      alt="Barney Douglas"
                    />
                    <h2 className="c-cast-listing__item-title">
                      Barney Douglas
                    </h2>
                  </div>
                  <div className="c-cast-listing__item-content-container">
                    <p>
                      The Edge Film is written, directed and produced by Barney Douglas. Barney’s second feature length documentary, The Edge Film is the culmination of years of hard work,
                      research and experience, that saw countless hours of essential cricketing archive material sifted through, on location filming taking place in the UK and abroad,
                      including Germany, the Caribbean and as far out as Australia, as well as interviews with some of the biggest names in English cricketing history.
                      Barney’s previous film, Warriors (2015) took him to Kenya to follow a remote group of young Maasai who formed a cricket team and used their newfound drive to
                      tackle some of the injustices found in their community. Before this Barney worked in film and cricket for many years, also producing Swanny’s Video Diaries
                      as the official Video Producer for the English Cricket Board, touring closely with the England camp all around the world from 2009 until 2013, during much of the
                      period of time in which the story of The Edge Film takes place.
                    </p>
                  </div>
                </div>
                <div className="c-cast-listing__item">
                  <div className="c-cast-listing__item-media-container">
                    <Img
                      fluid={
                        this.props.data.felixWhite.childImageSharp.fluid
                      }
                      alt="Felix White"
                    />
                    <h2 className="c-cast-listing__item-title">
                      Felix White
                    </h2>
                  </div>
                  <div className="c-cast-listing__item-content-container">
                    <p>
                      The Edge Film features a debut film soundtrack from Felix White (The Maccabees) which captures the frenetic energy, pace and beauty of the game of cricket and enhances
                      the film’s cinematic presentation and tone. As well as formerly being in one of Britain’s biggest bands, Felix also runs Yala! Records and is an avid cricket fan,
                      co-hosting the acclaimed cricket podcast Tailenders’, as well as having written cricket articles for sports and news publications.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Vendor className="c-vendor--inpage-light" />
          <div className="c-cta c-cta--nomargin-top c-cta--nomargin-bottom">
            <video
              src={ctaVideo}
              className="c-cta__video"
              autoPlay muted playsInline loop>
            </video>
            <div className="container">
              <div className="row">
                <div className="c-cta__link-container">
                  <Link className="c-cta__link" to="/where-to-watch">Where to Watch</Link>
                </div>
              </div>
            </div>
          </div>
        </article>
      </Layout>
    )
  }
}

export const fluidImage = graphql`
  fragment castImage on File {
    childImageSharp {
      fluid(maxWidth: 600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    alistairCook: file(relativePath: { eq: "cast/alistair-cook.jpg" }) {
      ...castImage
    }
    andrewFlower: file(relativePath: { eq: "cast/andrew-flower.jpg" }) {
      ...castImage
    }
    andrewStrauss: file(relativePath: { eq: "cast/andrew-strauss.jpg" }) {
      ...castImage
    }
    davidSaker: file(relativePath: { eq: "cast/david-saker.jpg" }) {
      ...castImage
    }
    graemeSwann: file(relativePath: { eq: "cast/graeme-swann.jpg" }) {
      ...castImage
    }
    ianBell: file(relativePath: { eq: "cast/ian-bell.jpg" }) {
      ...castImage
    }
    jamesAnderson: file(relativePath: { eq: "cast/james-anderson.jpg" }) {
      ...castImage
    }
    jonathanTrott: file(relativePath: { eq: "cast/jonathan-trott.jpg" }) {
      ...castImage
    }
    kevinPietersen: file(relativePath: { eq: "cast/kevin-pietersen.jpg" }) {
      ...castImage
    }
    mattPrior: file(relativePath: { eq: "cast/matt-prior.jpg" }) {
      ...castImage
    }
    montyPanesar: file(relativePath: { eq: "cast/monty-panesar.jpg" }) {
      ...castImage
    }
    paulCollingwood: file(relativePath: { eq: "cast/paul-collingwood.jpg" }) {
      ...castImage
    }
    stevenFinn: file(relativePath: { eq: "cast/steven-finn.jpg" }) {
      ...castImage
    }
    stuartBroad: file(relativePath: { eq: "cast/stuart-broad.jpg" }) {
      ...castImage
    }
    timBresnan: file(relativePath: { eq: "cast/tim-bresnan.jpg" }) {
      ...castImage
    }
    barneyDouglas: file(relativePath: { eq: "cast/barney-douglas.jpg" }) {
      ...castImage
    }
    felixWhite: file(relativePath: { eq: "cast/felix-white.jpg" }) {
      ...castImage
    }
  }
`

export default CastAndCrew
